import { encode } from "base-64";
import { methods } from "utils/services";
import { service, authenticatedService } from "./middlewares";

const { GET, POST } = methods;

const AUTH_URL = process.env.REACT_APP_AUTH_URL;

const routes = {
    login: process.env.REACT_APP_BASE_API_URL + "/auth/authorize",
    getToken: process.env.REACT_APP_BASE_API_URL + "/auth/token",
    supervise: `${AUTH_URL}/auth/supervise`
};

export default {
    login: (username, password) =>
        service(
            GET,
            routes.login,
            {},
            {
                prompt: "none",
                response_type: "code",
                redirect_uri: process.env.REACT_APP_BASE_API_URL,
                client_id: "stoquemarket_webapp"
            },
            {
                Authorization: "Basic " + encode(username + ":" + password)
            }
        ),
    getToken: (username, password, code) =>
        service(
            POST,
            routes.getToken,
            `grant_type=authorization_code&code=${code}&redirect_uri=${process.env.REACT_APP_BASE_API_URL}`,
            {},
            { Authorization: 'Basic ' + encode(username + ':' + password) },
        ),
    supervise: idUserToSupervise =>
        authenticatedService(POST, routes.supervise, { data: idUserToSupervise }),
    refreshToken: ({ authorization, refresh_token }) =>
        service(
            POST,
            routes.getToken,
            `grant_type=refresh_token&refresh_token=${refresh_token}`,
            {},
            { Authorization: "Basic " + authorization }
        ),
};